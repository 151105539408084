import React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsersCog, faChalkboardTeacher, faDesktop, faChartPie, faCogs, faPhone, faUserCheck, faHandsHelping } from '@fortawesome/free-solid-svg-icons'

class Services extends React.Component {


    render() {
        return (
            <Layout>
                <section className="section-wrapper full-page">
                    <header className="section-header">
                        <h3>Dienstleistungen</h3>
                    </header>
                    <div className="card-columns">
                        <div className="card">
                            <FontAwesomeIcon icon={faChalkboardTeacher} size="2x" />
                            <div className="service-content">
                                <h3>Einführungen</h3>
                                <p>
                                    Als IT-Spezialist für Personalinformationssysteme und Prozessmanagement betreuen wir Kunden, die Abacus Lohn, HR-Portale
                                    und HR-Prozesse einführen möchten oder aktuell bereits die Standardsoftware Abacus HR implementiert haben.
                                </p>
                                <p>
                                    Unsere Kunden schätzen es, einen rasch erreichbaren und erfahrenen Ansprechpartner zu haben. Bei uns plant der Kunde,
                                    in welchem Umfang er unsere Dienstleistungen benötigt, und wann die interne Informatikabteilung hinzugezogen wird.
                                    Schulung und Ausbildung interner Mitarbeitenden ist für das Gelingen von Projekten wichtig und ist uns deshalb ein grosses Anliegen.
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <FontAwesomeIcon icon={faUsersCog} size="2x" />
                            <div className="service-content">
                                <h3>HR Prozessmanagement</h3>
                                <p>
                                    Wir unterstützen unsere Kunden beim Optimieren von Geschäftsprozessen. Fachabteilungen sind gefordert, Prozesse,
                                    die sich in einem Unternehmen stetig verändern, souverän zu meistern. Als IT-Dienstleister realisieren wir bei unseren
                                    Kunden flexible Prozesslösungen. Wir garantieren dabei einen stabilen Betrieb und schützen Investitionen, indem unsere
                                    Prozesse bei Bestehendem „andocken“.
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <FontAwesomeIcon icon={faChartPie} size="2x" />
                            <div className="service-content">
                                <h3>Reporting und HR Dashboards</h3>
                                <p>
                                    Unser Firmenspruch von 1995 „Wir machen aus Daten Informationen“ hat kontinuierlichan Bedeutung gewonnen. Wir realisieren für
                                    unsere Kunden seit langem Auswertungsplattformen, die einer Personalabteilung ermöglichen, leicht, rasch und optisch ansprechende
                                    Reports für den eigenen Bedarf, für andere Abteilungen und Führungsgremien zu erstellen. Zudem binden wir dieses Reporting in
                                    die von uns entwickelten HR Portale ein. LEPA verfügt über ein eigenes HR Dashboard auf Basis der Abacus Dataanalyser Komponente.
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <FontAwesomeIcon icon={faDesktop} size="2x" />
                            <div className="service-content">
                                <h3>HR Portal</h3>
                                <p>
                                    Wir realisieren für unsere Kunden HR Portale auf Basis von MyAbacus, die von Vorgesetzten, Mitarbeitenden, Bewerbern und der
                                    Personalabteilung als zentrale Kommunikationsplattform genutzt werden können. Unsere HR Portale
                                </p>
                                <ul>
                                    <li>reduzieren interne Kosten (Arbeiten werden an den Entstehungsort übergeben),</li>
                                    <li>steigern die Effizienz (z. B. pflegen Mitarbeitende ihre Daten selber),</li>
                                    <li>stärken die Position der HR Abteilung (strategische Informationen können rasch an Führungskräfte gegeben werden),</li>
                                    <li>fördern die Zufriedenheit der Mitarbeitenden (Eigenverantwortung wird geschätzt, das Arbeiten in einer modernen Unternehmung gefällt.)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <FontAwesomeIcon icon={faCogs} size="2x" />
                            <div className="service-content">
                                <h3>Entwicklung</h3>
                                <p>
                                    Ob es sich um das Einbinden des Personalinformationssystems in bestehende Informatiklandschaften, um HR Portale, HR Prozesse oder
                                    Zusatzentwicklungen handelt, wir entwickeln für alle Aufgaben sinnvolle Lösungen.
                                </p>
                            </div>
                        </div>
                        <div className="card">
                            <FontAwesomeIcon icon={faPhone} size="2x" />
                            <div className="service-content">
                                <h3>Support</h3>
                                <p>
                                    Für unsere Kunden sind wir jederzeit schnell erreichbar. Während unseren Geschäftszeiten stehen die jeweiligen Projektverantwortlichen
                                    unseren Kunden telefonisch zur Verfügung. Jedes Kundenprojekt wird bei uns durch mehrere hervorragend ausgebildete Informatiker
                                    betreut und es wird sichergestellt, dass der Knowhowtransfer innerhalb des Teams wöchentlich sichergestellt wird. Dank der zentralen
                                    Lage von Luzern sind unsere Kunden auch vor Ort in angemessenem Zeitraum zu erreichen. Wir kennen keine telefonischen Warteschlaufen!
                                </p>
                            </div>
                        </div>
                        {/*<div className="card">
                            <FontAwesomeIcon icon={faHandsHelping} size="2x" />
                            <div className="service-content">
                                <h3>Outsourcing</h3>
                                <p>
                                    Outsourcing bieten wir in den verschiedensten Ausprägungen an. Auf Wunsch betreiben wir für unsere Kunden das HR-System
                                    (Hard- und Software) in unseren Büroräumlichkeiten in Luzern oder beim Kunden vor Ort.
                                </p>
                            </div>
        </div>*/}
                        <div className="card">
                            <FontAwesomeIcon icon={faUserCheck} size="2x" />
                            <div className="service-content">
                                <h3>Reviews</h3>
                                <p>
                                    Wir bieten HR-Abteilungen Organisations-Reviews, technische und Machbarkeits-Reviews an. Unsere Reviews klären, ob für ein Unternehmen
                                    Handlungsbedarf in seiner HR-Umgebung besteht und wir liefern sowohl Argumente für Veränderungen als auch konkrete Vorschläge zur
                                    Effizienzsteigerung.
                                </p>
                                <p>
                                    Da bei der Einführung und beim Betrieb von Software sich aufgrund von Zeitmangel, Informationsflut und ungenügender Schulung punktuell
                                    Mängel manifestieren können, verschafft ein Review Überblick über:
                                </p>
                                <ul>
                                    <li>das bestehende System</li>
                                    <li>Schnittstellen</li>
                                    <li>Umsysteme</li>
                                    <li>Ablauforganisation, Prozessmanagement</li>
                                    <li>Stand der Mitarbeiterschulung</li>
                                    <li>Ideen für Verbesserungen</li>
                                    <li>Security-System</li>
                                    <li>Backup</li>
                                    <li>Testsystem</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout >
        )
    }
}

export default Services
